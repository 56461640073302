<template>
  <div>
    <!-- 众创服务页面 -->
    <Header title="众创服务" index="首页" titleOne="官网管理" beforeTitle="众创服务" />
    <div class="content">
      <div class="topUsers" style="margin-top: 20px;">
        <div>
          <el-input
            clearable
            v-model="input"
            placeholder="请输入企业名称关键字"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-button class="search" @click="handleSearch()">查询</el-button>
        </div>
        <el-button class="search" @click="add()">新增</el-button>
      </div>
      <el-table :data="tableData.data" style="width: 100%">
        <el-table-column prop="img" label="企业封面/LOGO">
          <template slot-scope="scope">
            <el-popover placement="top-start" title trigger="hover">
              <img :src="gqbpath + scope.row.cover_url" alt style="height: 180px;" />
              <img slot="reference" :src="gqbpath + scope.row.cover_url" style="height: 90px;" />
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="company_name" label="企业名称"></el-table-column>
        <el-table-column prop="tel" label="电话"></el-table-column>
        <el-table-column prop="address" label="办公地址"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button class="edit" size="small" @click="goEdit(scope.row.id)">编辑</el-button>
            <el-button class="del" size="small" @click="goDel(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="1"
          :page-sizes="[2, 10, 20, 40]"
          :page-size="10"
          layout="sizes, prev, pager, next,total,jumper"
          :total="tableData.total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Gqburl from '../../components/global.vue'
export default {
  data () {
    return {
      gqbpath: Gqburl.userSite,
      input: '',
      tableData: [],
      giveParams: {
        page: 1,
        limit: 10,
        service_type: '',
        keywords: '',
      }
    }
  },

  activated () {
    this.giveParams
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      this.axios.get('/api/website/service_list', { params: { ...this.giveParams } }).then((res) => {
        console.log(res, '众创服务列表')
        this.tableData = res.data
      })
    },
    handleCurrentChange (val) {
      this.getList(this.giveParams.page = val)
    },
    handleSizeChange (val) {
      this.getList(this.giveParams.limit = val)
    },
    handleSearch () {
      this.getList(this.giveParams.keywords = this.input)
    },
    add () {
      this.$router.push({ path: '/website/service/service_add' })
    },
    goEdit (id) {
      this.$router.push({ path: '/website/service/service_add', query: { id: id, type: 'service' } })
    },
    goDel (id) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.delete('/api/website/delete_service', {
          params: {
            id: id
          }
        }).then((res) => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.getList()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
  },
}
  </script>
  
  <style scoped>
.flex {
  display: flex;
}
</style>